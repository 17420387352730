<template>
  <div>
    <dynamic-template :name="mainTemplate"></dynamic-template>
    <div :class="'single-logo ' + logoClass">
      <dynamic-template name="Logo"></dynamic-template>
    </div>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout11',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    },
    logoClass() {
      return this.$store.state.logoPosition || 'bottom-right';
    }
  }
};
</script>

<style lang="scss">
.single-logo {
  position: fixed;
  margin: 36px;

  img {
    width: 180px;
    height: auto;
  }

  &.left-top {
    left: 0;
    top: 0;
  }

  &.left-bottom {
    left: 0;
    bottom: 60px;
  }

  &.right-top {
    right: 0;
    top: 0;
  }

  &.right-bottom {
    right: 0;
    bottom: 60px;
  }
}</style>
